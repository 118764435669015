import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateEntry from '../pages/CreateEntry';
import NotFound from '../pages/NotFound';

export default function Router() {
  return (
    <Routes>
      <Route path="shop/:id" element={<CreateEntry />} />
      <Route path="*" element={<NotFound />} />

      {/* <Route path="/" element={<Homepage />} />
      <Route path="shop" element={<Shop />} />
      <Route path="product/:id" element={<Product />} />
      <Route path="content" element={<ContentOutlet />}>
        <Route index element={<ContentList />} />
        <Route path=":id" element={<Content />} />
      </Route>
      {!!user.data && (
        <>
          <Route path="account" element={<AccountOutlet />}>
            <Route index element={<Overview />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="orders" element={<Orders />} />
          </Route>
          <Route path="checkout" element={<Checkout />} />
          <Route path="checkout/shipping" element={<Shipping />} />
        </>
      )}
      {!user.data && (
        <>
          <Route path="signin" element={<SignIn />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </>
      )}
      <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}
