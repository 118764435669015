import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentData,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore';
import { doc, query, where } from 'firebase/firestore';
import { collections } from '../firebase';

export function useGetShop(shopId: string) {
  return useFirestoreDocumentData(['shop', shopId], doc(collections.shops, shopId));
}

export function useGetShopServices(shopId: string) {
  return useFirestoreQueryData(['shop', shopId, 'services'], query(collections.shopServices(shopId)));
}

export function useGetIsBlackListed(shopId: string, phoneNumber: string) {
  return useFirestoreQueryData(
    ['shop', shopId, 'blacklist', phoneNumber],
    query(collections.shopBlackList(shopId), where('phoneNumber', '==', phoneNumber)),
  );
}

export function useGetShopServiceSpecialities(shopId: string, serviceId?: string) {
  return useFirestoreQueryData(
    ['shop', shopId, 'services', serviceId, 'specialities'],
    async () => {
      if (!serviceId) {
        throw new Error('Service ID is required');
      }

      return query(collections.shopServiceSpecialities(shopId, serviceId));
    },
    {},
    {
      enabled: !!serviceId,
    },
  );
}

export function useGetUnAvailableEntries(shopId: string, serviceId: string, specialityId: string, date: Date) {
  return useFirestoreQueryData(
    ['shop', shopId, 'entries', serviceId, specialityId, date],
    async () => {
      if (!serviceId || !specialityId) {
        throw new Error('Service ID and speciality ID are required');
      }
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      return query(
        collections.entries,
        where('shopId', '==', shopId),
        where('serviceId', '==', serviceId),
        where('specialityId', '==', specialityId),
        where('date', '>=', startDate),
        where('date', '<=', endDate),
      );
    },
    {},
    {
      enabled: !!serviceId && !!specialityId,
    },
  );
}

export function useCreateEntry() {
  return useFirestoreCollectionMutation(collections.entries);
}
