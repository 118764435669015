import { Timestamp } from 'firebase/firestore';

export type Shop = {
  id: string;
  name: string;
  address: string;
  phoneNumber: string;

  settings: Settings;
};

export type Settings = {
  breakdownBy: number;

  weeklyBreakdown: WeeklyBreakdown;
};

export enum Day {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export type WeeklyBreakdown = {
  [key in Day]: DailyBreakdown;
};

export type DailyBreakdown = {
  isAvailable: boolean;

  start: string;
  end: string;
};

export type Vacation = {
  startDate: Timestamp;
  endDate: Timestamp;
};
