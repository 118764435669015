import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { Entry } from '../types/entry';
import { Service, Speciality } from '../types/service';
import { Shop } from '../types/shop';

export const productConverter: FirestoreDataConverter<Shop> = {
  fromFirestore(snapshot): Shop {
    const data = snapshot.data();

    return {
      id: snapshot.id,
      name: data.name,
      address: data.address,
      phoneNumber: data.phoneNumber,
      settings: {
        breakdownBy: data.settings.breakdownBy,
        weeklyBreakdown: {
          monday: {
            isAvailable: data.settings.weeklyBreakdown.monday.isAvailable,
            start: data.settings.weeklyBreakdown.monday.start,
            end: data.settings.weeklyBreakdown.monday.end,
          },
          tuesday: {
            isAvailable: data.settings.weeklyBreakdown.tuesday.isAvailable,
            start: data.settings.weeklyBreakdown.tuesday.start,
            end: data.settings.weeklyBreakdown.tuesday.end,
          },
          wednesday: {
            isAvailable: data.settings.weeklyBreakdown.wednesday.isAvailable,
            start: data.settings.weeklyBreakdown.wednesday.start,
            end: data.settings.weeklyBreakdown.wednesday.end,
          },
          thursday: {
            isAvailable: data.settings.weeklyBreakdown.thursday.isAvailable,
            start: data.settings.weeklyBreakdown.thursday.start,
            end: data.settings.weeklyBreakdown.thursday.end,
          },
          friday: {
            isAvailable: data.settings.weeklyBreakdown.friday.isAvailable,
            start: data.settings.weeklyBreakdown.friday.start,
            end: data.settings.weeklyBreakdown.friday.end,
          },
          saturday: {
            isAvailable: data.settings.weeklyBreakdown.saturday.isAvailable,
            start: data.settings.weeklyBreakdown.saturday.start,
            end: data.settings.weeklyBreakdown.saturday.end,
          },
          sunday: {
            isAvailable: data.settings.weeklyBreakdown.sunday.isAvailable,
            start: data.settings.weeklyBreakdown.sunday.start,
            end: data.settings.weeklyBreakdown.sunday.end,
          },
        },
      },
    };
  },
  toFirestore() {
    throw new Error('Client does not support updating shops.');
  },
};

export const serviceConverter: FirestoreDataConverter<Service> = {
  fromFirestore(snapshot): Service {
    const data = snapshot.data();

    return {
      id: snapshot.id,
      name: data.name,
    };
  },
  toFirestore() {
    throw new Error('Client does not support updating services.');
  },
};

export const specialityConverter: FirestoreDataConverter<Speciality> = {
  fromFirestore(snapshot): Speciality {
    const data = snapshot.data();

    return {
      id: snapshot.id,
      name: data.name,
      duration: data.duration,
      price: data.price,
      toPrice: data.toPrice,
      type: data.type,
    };
  },
  toFirestore() {
    throw new Error('Client does not support updating specialities.');
  },
};

export const blackListConverter: FirestoreDataConverter<string> = {
  fromFirestore(snapshot): string {
    const data = snapshot.data();

    return data.phoneNumber;
  },
  toFirestore() {
    throw new Error('Client does not support updating black list.');
  },
};

export const entryConverter: FirestoreDataConverter<Entry> = {
  fromFirestore(snapshot) {
    const data = snapshot.data();

    return {
      id: snapshot.id,
      shopId: data.shopId,
      serviceId: data.serviceId,
      specialityId: data.specialityId,

      date: data.date as Timestamp,
      duration: data.duration,
      price: data.price,

      comment: data.comment,

      client: {
        name: data.client.name,
        phoneNumber: data.client.phoneNumber,
      },

      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      deletedAt: data.deletedAt,
    };
  },
  toFirestore(entry: Entry) {
    return {
      shopId: entry.shopId,
      serviceId: entry.serviceId,
      specialityId: entry.specialityId,

      date: entry.date,
      duration: entry.duration,
      price: entry.price,
      comment: entry.comment,

      client: {
        name: entry.client.name,
        phoneNumber: entry.client.phoneNumber,
      },

      createdAt: entry.createdAt,
      updatedAt: entry.updatedAt,
      deletedAt: null,
    };
  },
};
