import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { collection, getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import {
  blackListConverter,
  entryConverter,
  productConverter,
  serviceConverter,
  specialityConverter,
} from './converters';

export const firebaseConfig = {
  apiKey: 'AIzaSyDT8lb4_7Mp6q-y22sNu3FlT92eGf_ogqM',
  authDomain: 'bookly-one.firebaseapp.com',
  projectId: 'bookly-one',
  storageBucket: 'bookly-one.appspot.com',
  messagingSenderId: '1005534481014',
  appId: '1:1005534481014:web:8a075c65d10dadb80eed57',
  measurementId: 'G-84774SFRLH',
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export const collections = {
  shops: collection(firestore, 'shops').withConverter(productConverter),
  shopServices: (shopId: string) => collection(firestore, 'shops', shopId, 'services').withConverter(serviceConverter),
  shopBlackList: (shopId: string) =>
    collection(firestore, 'shops', shopId, 'blackList').withConverter(blackListConverter),
  shopServiceSpecialities: (shopId: string, serviceId: string) =>
    collection(firestore, 'shops', shopId, 'services', serviceId, 'specialities').withConverter(specialityConverter),
  entries: collection(firestore, 'entries').withConverter(entryConverter),
  // products: collection(firestore, "products").withConverter(productConverter),
  // customers: collection(firestore, "customers").withConverter(customerConverter),
  // // TODO(ehesp): Add converter once schema agreed.
  // cart: collection(firestore, "cart"),
  // sessions: (customerId: string): CollectionReference<Session> =>
  // 	collection(firestore, "customers", customerId, "checkout_sessions").withConverter(sessionConverter),
  // payments: (customerId: string): CollectionReference => collection(firestore, "customers", customerId, "payments"),
  // subscriptions: (customerId: string): CollectionReference<Subscription> =>
  // 	collection(firestore, "customers", customerId, "subscriptions").withConverter(subscriptionConverter),
  // invoices: (customerId: string, subscriptionId: string): CollectionReference => {
  // 	return collection(collections.subscriptions(customerId), subscriptionId, "invoices");
  // },
  // productReviews: (productId: string): CollectionReference<Review> =>
  // 	collection(firestore, "products", productId, "reviews").withConverter(reviewConverter),
  // content: collection(firestore, "content").withConverter(contentConverter),
  // addresses: (customerId: string) => collection(firestore, "customers", customerId, "addresses").withConverter(addressConverter),
};
