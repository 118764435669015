import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function NotFound() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Typography textAlign="center" variant="h5">
          404 - Not Found
        </Typography>
        <Typography textAlign="center" variant="body1">
          Bookly One
        </Typography>
      </Grid>
    </Grid>
  );
}
